import { Timestamp } from 'firebase/firestore'
import { AuthUser } from 'next-firebase-auth'
import { Placement } from 'src/components/Tippy'

import { UserProfileDataDoc } from './store/types'

export interface DatabaseDefaults<Data> {
  id: string
  created: Date
  modified: Date
  createdBy: string
  // modifiers
  update: (entity: Partial<Data>, userId: string | null) => void
  delete: (userId: string | null) => void
}

export type Flow = FlowData & DatabaseDefaults<FlowData>

export enum LinkType {
  button = 1,
  link = 2,
}

export enum AppScope {
  main = 1,
  edit = 2,
  admin = 3,
  public = 4,
}

export enum TopLevelNavItem {
  ARCADES = 'My Arcades',
  ANALYTICS = 'Analytics',
}
export type TopLevelNavItemKey = keyof typeof TopLevelNavItem

interface Link {
  type: LinkType
  url: string
  title: string
  description?: string
  color?: string
}

interface CTAColors {
  modalBg?: string
  modalText?: string
  buttonBg?: string
  buttonText?: string
}

export enum PublishStatus {
  draft = 1,
  published = 2,
  archived = 3,
  deleted = 4,
}

export interface CTA {
  id?: string
  title?: string
  description?: string
  colors?: CTAColors
  links?: Link[]
}

export interface BackgroundImage {
  url: string
  html_url: string
  download_url: string
  thumb_url: string
  blurhash?: string
}
export interface FlowData {
  name: string
  description?: string
  schemaVersion: string
  aspectRatio: number
  cta: CTA
  steps: Step[]
  createdBy: string
  editors?: string[]
  status?: PublishStatus
  publishedDate?: Date
  flowWrapper: FlowWrapper
  font?: string
  bgImage?: BackgroundImage | null
  heroImageUrl?: string
  group?: string
  createdWith?: string
  experiments?: string[]
  experimentsConfig?: { [key: string]: any }
  showArcadeButton?: boolean | null
  openingAnimation?: OpeningAnimation
  belongsToTeam?: boolean
  createdByUser?: Partial<AuthUser>
  showStartOverlay?: boolean
  startOverlayButtonText?: string
  preventIndexing?: boolean
}

export enum OpeningAnimation {
  'None' = 'None',
  'Fade and Grow' = 'Fade and Grow',
}

export enum FlowWrapper {
  none = 1,
  browserLight = 2,
  browserDark = 3,
}

export interface Step {
  id: string
  name: string
  imageUrl?: string
  url: string
  streamUrl?: string
  videoThumbnailUrl?: string
  blurhash?: string
  type: MediaType
  hotspots: Hotspot[]
  cta: CTA
  targetId?: string | null
  playbackRate?: number
  assetId?: string | null
}

export interface Hotspot {
  id: string
  x: number
  y: number
  width: number
  height: number
  targetId?: string | null
  label: string
  labelColor?: string // deprecated as of 9/23/2021
  textColor?: string
  bgColor?: string
  defaultOpen?: boolean
  displayAsCaption?: boolean
  isDirty?: boolean
  preferredDirection?: Placement
}

export interface Canvas {
  height: number
  width: number
}

export type Image = ImageData & DatabaseDefaults<ImageData>

export interface ImageData {
  url: string
  blurhash?: string
}

export type Video = VideoData & DatabaseDefaults<VideoData>

export interface VideoData {
  url: string
  streamUrl?: string
  videoThumbnailUrl?: string
  label?: string
  assetId?: string
}

export type CollaboratorToken = CollaboratorTokenData &
  DatabaseDefaults<CollaboratorTokenData>

export interface CollaboratorTokenData {
  flow: string
}

export type ColorsProp = {
  bgColor?: string
  textColor?: string
}

export type UserPrefs = {
  fontFamily?: string
  bgColor?: string
  textColor?: string
}

export type UserProfileData = UserProfileDataDoc & {
  isFreeUser: boolean
  isProUser: boolean
}

export type UserProfile = UserProfileData & DatabaseDefaults<UserProfileData>

type NonOptionalNullableExceptBooleans<T> = boolean extends T
  ? boolean
  : T extends CallableFunction
  ? T
  : T extends Record<string, any>
  ? {
      [P in keyof T]-?: NonOptionalNullableExceptBooleans<T[P]>
    }
  : T | null

// An arranged mix of the UserProfile and the AuthUser
// (we removed `displayName`, `phoneNumber` and `claims`)
// default values are null.
export type User = NonOptionalNullableExceptBooleans<
  Omit<AuthUser, 'displayName' | 'phoneNumber' | 'claims'>
> &
  NonOptionalNullableExceptBooleans<UserProfileData> & {
    // Properties calculated "on the fly" from AuthUser:
    isLoggedIn: boolean // inferred from the presence of `id`
    // Properties (objects) with a non-null default value:
    prefs: UserPrefs | null
  }

export enum MediaType {
  Image = 1,
  Video = 2,
}

export interface Size {
  height: number
  width: number
}

export enum Feature {
  NoArcadeBranding = 'No Arcade Branding',
  Analytics = 'Analytics',
  PublishEventsToHost = 'Publish Events to Host',
  DisableIPTracking = 'Disable IP Tracking',
  PreventIndexing = 'Prevent Indexing',
  DoNotTrack = 'Do Not Track',
  // Temporary features:
  // To disable a temporary feature:
  // - Remove it from the code
  // - Remove it from Firestore (userProfiles, Teams and Global)
  // - Remove it from Retool
  PaywallArcadeLimit = 'Paywall Arcade Limit',
  BrowserBackForwardButtons = 'Display Browser Back/Forward Buttons',
  OnboardingFormRequiredAfterTimestamp = 'Onboarding Form Required After Timestamp',
  SelfService = 'Self Service',
}

export type StoredFeatures = {
  [Feature.NoArcadeBranding]?: boolean | undefined
  [Feature.Analytics]?: boolean | undefined
  [Feature.PublishEventsToHost]?: boolean | undefined
  [Feature.DisableIPTracking]?: boolean | undefined
  [Feature.PreventIndexing]?: boolean | undefined
  [Feature.DoNotTrack]?: boolean | undefined
  [Feature.PaywallArcadeLimit]?: number | undefined
  [Feature.BrowserBackForwardButtons]?: boolean | undefined
  [Feature.OnboardingFormRequiredAfterTimestamp]?: Timestamp | undefined
  [Feature.SelfService]?: {
    ChromePopups: boolean | undefined
    LoggedHomeEnhancements: boolean | undefined
    GettingStartedSection: boolean | undefined
  }
}

export type Features = Required<StoredFeatures>

export enum ConfigType {
  Features = 'Features',
}
export type Configs = {
  [ConfigType.Features]: StoredFeatures
}
export type ConfigData = Configs[keyof Configs]
export type Config = ConfigData & DatabaseDefaults<ConfigData>

export type MemberType = 'Admin' | 'Member'

export type TeamMemberData = {
  id: string
  email: string
  type: MemberType
  status: 'Pending' | 'Active' | 'Deactivated' | 'Requested'
  externalMember?: boolean
  invitationId?: string
  displayName?: string
  photoURL?: string
  lastSigninTime?: number
}
export type TeamMember = TeamMemberData & DatabaseDefaults<TeamMemberData>

export type TeamData = {
  name: string
  group: string
  features: StoredFeatures
  currentSubscriber: boolean
  activeMemberCount?: number
  prefs?: TeamPrefs
  inviteByUrlId?: string
  customerId?: string
}

export type Team = TeamData & DatabaseDefaults<TeamData>

export type TeamPrefs = {
  fontFamily?: string
  bgColor?: string
  fgColor?: string
  bgImage?: BackgroundImage
  showArcadeButton?: boolean
  wrapper?: FlowWrapper
  showStartOverlay?: boolean
  startOverlayButtonText?: string
}

export type TeamInvitationData = {
  inviterName: string
  inviterEmail: string
  teamName: string
  group: string
  inviteeEmail: string
  inviteeId?: string
  invitationUrlPrefix: string
  status: 'Pending' | 'Accepted' | 'Requested' | 'Rejected'
}

export type TeamInvitation = TeamInvitationData &
  DatabaseDefaults<TeamInvitationData>

export type WebhookMuxUploadsData = {
  data: object
}

export type WebhookMuxUploads = WebhookMuxUploadsData &
  DatabaseDefaults<WebhookMuxUploadsData>

export type WebhookMuxAssetsData = {
  data: object
}

export type WebhookMuxAssets = WebhookMuxAssetsData &
  DatabaseDefaults<WebhookMuxAssetsData>

export enum ProductPlans {
  Free = 'free',
  ProMonthly = 'pro-monthly',
  ProAnnual = 'pro-annual',
  ProAnnual50Off = 'pro-annual-50',
  TeamMonthly = 'team-monthly',
  TeamAnnual = 'teamp-annual',
}

import * as Sentry from '@sentry/nextjs'
import { captureException, withScope } from '@sentry/nextjs'
import { Extras } from '@sentry/types'
import { AxiosError } from 'axios'
import { decode } from 'blurhash'
import invert, { BlackWhite, Color, HexColor } from 'invert-color'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { DateTime } from 'luxon'

import { FirebaseConfig } from '../constants'
import {
  Flow,
  FlowWrapper,
  MediaType,
  Size,
  Step,
  Team,
  User,
  UserProfile,
} from './types'

export const isBrowser =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined'

export function widthAsPercentage(
  width: number,
  canvas: { height: number; width: number }
) {
  return width / (canvas.width ?? 1)
}

export function heightAsPercentage(
  height: number,
  canvas: { height: number; width: number }
) {
  return height / (canvas.height ?? 1)
}

export function percentageAsWidth(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.width ?? 1) * percentage
}

export function percentageAsHeight(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.height ?? 1) * percentage
}

export function formatTimeSince(value: number | Date) {
  const dttm =
    typeof value === 'number'
      ? DateTime.fromMillis(value)
      : DateTime.fromJSDate(value)

  return DateTime.now().minus(DateTime.now().diff(dttm)).toRelative()
}

const toBase64 = (str: string) =>
  !isBrowser ? Buffer.from(str).toString('base64') : window.btoa(str)

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#ccc" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

export const shimmerDataUrl = (h: number, w: number) =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`

export const blurCache: { [key: string]: string } = {}

export const getBlurDataUrlFromBlurhash = (
  blurhash?: string,
  width = 32,
  height = 32
) => {
  width = Math.round(width)
  height = Math.round(height)

  // server-size rendering fallback
  if (typeof document === 'undefined') {
    return shimmerDataUrl(width, height)
  }
  const cacheKey = `${blurhash}-${width}-${height}`

  if (blurCache[cacheKey]) {
    return blurCache[cacheKey]
  }

  let blurDataUrl = ''

  if (blurhash) {
    const pixels = decode(blurhash, width, height)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    try {
      const imgData = ctx!.createImageData(width, height)
      imgData.data.set(pixels)
      ctx!.putImageData(imgData, 0, 0)
      blurDataUrl = canvas.toDataURL()
    } catch (error) {
      // ignored because we use the shimmer data below instead
    }
  }

  if (!blurDataUrl) {
    blurDataUrl = shimmerDataUrl(width, height)
  }

  blurCache[cacheKey] = blurDataUrl
  return blurDataUrl
}

export const interpolate = (begin: number, end: number, curr: number) =>
  begin + (curr / 99) * (end - begin)

export const getHeightAndWidthOfFirstStep = (
  step: Step,
  cb: (dim: Size) => void
) => {
  if (
    step?.type === MediaType.Image ||
    (step?.type === MediaType.Video && step.videoThumbnailUrl)
  ) {
    if (
      step.url.startsWith('https://cdn.arcade.software') &&
      step.type === MediaType.Image
    ) {
      const attrUrl = `/api/images/attributes/${
        step.url.split('?')[0].split('/').slice(-1)[0]
      }`
      fetch(attrUrl)
        .then(attrs => {
          if (attrs.ok) {
            attrs.json().then(({ width, height }) => cb({ width, height }))
          }
        })
        .catch(err => captureException(err))
    } else {
      const img: HTMLImageElement = document.createElement('img')
      img.onload = () => {
        if (img) {
          cb({ height: img.height, width: img.width })
          img.src = ''
        }
      }

      // we only need a blurry image to get the ratio
      img.src = optimizedImageUrl(
        step?.type === MediaType.Video && step.videoThumbnailUrl
          ? step.videoThumbnailUrl
          : step.url,
        'q=0'
      )
    }
  } else if (step?.type === MediaType.Video && !step.videoThumbnailUrl) {
    // Left here for backwards compatibility for video steps without a `videoThumbnailUrl`
    let vid: HTMLVideoElement | null = document.createElement('video')
    vid.autoplay = false
    vid.onloadedmetadata = () => {
      if (!vid) return
      cb({ height: vid.videoHeight, width: vid.videoWidth })
      vid.src = ''
      vid = null
    }
    vid.src = step.url
  } else {
    Sentry.captureException(new Error('Unhandled ratio'), { extra: { step } })
  }
}

export const concatUnique = <T>(array: T[], newElement: T): T[] => {
  const set = new Set(array)
  set.add(newElement)
  return Array.from(set)
}

export const selectElementContents = (el: HTMLElement) => {
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel?.removeAllRanges()
  sel?.addRange(range)
}

export function getPositionAndBounds(
  x: number,
  y: number,
  stageDimensions: { height: number; width: number },
  containerDimensions: { top: number; left: number }
) {
  const topBound = 10
  const leftBound = 10
  const rightBound = stageDimensions.width - 10
  const bottomBound = stageDimensions.height - 10
  const top = Math.min(
    Math.max(y - containerDimensions.top - 2, topBound),
    bottomBound
  )
  const left = Math.min(
    Math.max(x - containerDimensions.left - 2, leftBound),
    rightBound
  )
  return { x: left, y: top, topBound, leftBound, rightBound, bottomBound }
}

export const mouseIsAlmostWithinElement = (
  event: React.MouseEvent,
  element: Element,
  padding: number
) => {
  const rect = element.getBoundingClientRect()
  return (
    event.clientX >= rect.x - padding &&
    event.clientX <= rect.x + rect.width + padding &&
    event.clientY >= rect.y - padding &&
    event.clientY <= rect.y + rect.height + padding
  )
}

export function optimizedImageUrl(url: string, filterOpts?: string) {
  if (!filterOpts) {
    filterOpts = 'fit=scale-down,f=auto'
    if (!isBrowser || !window?.innerWidth) {
      filterOpts = 'fit=scale-down,f=auto,width=1920,q=75'
    } else {
      const zoomRatio =
        (1 -
          //@ts-ignore
          Math.abs(window.innerWidth - 2000) /
            //@ts-ignore
            Math.max(window.innerWidth, 2000)) *
        100
      filterOpts = `${filterOpts},width=${window.innerWidth},q=${interpolate(
        60,
        75,
        zoomRatio
      )}`
      if (window.devicePixelRatio) {
        filterOpts = `${filterOpts},dpr=${window.devicePixelRatio}`
      }
    }
  }

  // This uses Cloudfront's image optimization service
  // https://developers.cloudflare.com/image-resizing/url-format
  return url.replace(
    'cdn.arcade.software/',
    `cdn.arcade.software/cdn-cgi/image/${filterOpts}/`
  )
}

export const integerOrDefault = (
  input: string | null,
  defaultTo = 0
): number => {
  if (input === null) return defaultTo
  return parseInt(input)
}

export const quotientOrNull = (
  numerator: string | number | null,
  denominator: string | number | null
): number | null => {
  if (numerator === '0' || numerator === 0) {
    return 0
  }
  if (denominator === '0' || denominator === 0 || denominator === null) {
    return null
  }
  if (numerator === null) {
    return 0
  }
  return +numerator / +denominator
}

export function isPointingToProduction() {
  return FirebaseConfig.projectId === 'arcade-cf7d5'
}

export function isProductionEnv() {
  return (
    process.env.VERCEL_ENV === 'production' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  )
}

export function isPreviewEnv() {
  return (
    process.env.VERCEL_ENV === 'preview' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  )
}

export function getBaseUrl(forDemoHost = false) {
  if (isProductionEnv() && !forDemoHost) {
    return 'https://app.arcade.software'
  }

  if (isProductionEnv() && forDemoHost) {
    return 'https://demo.arcade.software'
  }

  if (isPreviewEnv()) {
    return `https://${
      process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
    }`
  }

  return 'http://localhost:3000'
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function hexColorRegex() {
  return /#([a-f0-9]{3}|[a-f0-9]{6}|[a-f0-9]{8})\b/gi
}

const isAxiosError = (e: unknown): e is AxiosError => {
  return (
    typeof e === 'object' &&
    e !== null &&
    'isAxiosError' in e &&
    (e as any).isAxiosError
  )
}

const jsonParseIfPossible = (input: string): string | object => {
  try {
    return JSON.parse(input)
  } catch (_e) {
    return input
  }
}

const headersToOmit = ['authorization', 'Authorization']

export const captureExceptionWithRequestData = (e: unknown) => {
  if (isAxiosError(e)) {
    const extras: Extras = {}
    if (e.config.headers) {
      extras['request_headers'] = omit(e.config.headers, headersToOmit)
    }
    if (e.config.params) {
      extras['request_params'] = e.config.params
    }
    if (e.config.data) {
      extras['request_data'] = jsonParseIfPossible(e.config.data)
    }
    if (e.response) {
      extras['response_data'] = e.response.data
      extras['response_headers'] = omit(e.response.headers, headersToOmit)
      extras['response_status'] = e.response.status
      extras['response_status_text'] = e.response.statusText
    }
    Sentry.withScope(scope => {
      scope.setExtras(extras)
      Sentry.captureException(e)
    })
  } else {
    Sentry.captureException(e)
  }
}

export const safeInvertColor = (
  color: Color,
  bw?: BlackWhite | boolean
): HexColor => {
  try {
    if (typeof color === 'string' && color.length > 6) {
      color = color.substring(0, 6)
    }
    return invert(color, bw)
  } catch (e) {
    return '#ffffff'
  }
}

export function pickAllowedProps(flow: Flow) {
  return {
    ...pick(flow, [
      'id',
      'name',
      'description',
      'aspectRatio',
      'cta',
      'steps',
      'createdBy',
      'editors',
      'status',
      'flowWrapper',
      'font',
      'bgImage',
      'heroImageUrl',
      'group',
      'showArcadeButton',
      'openingAnimation',
      'belongsToTeam',
      'showStartOverlay',
      'startOverlayButtonText',
      'preventIndexing',
    ]),
    modified: flow.modified.getTime(),
    created: flow.created.getTime(),
  }
}

export function isCurrentCustomer(
  customer: User | UserProfile | Team | undefined
) {
  return customer?.customerId && customer?.currentSubscriber
}

export class HistoryStack<T> {
  protected stack: T[]
  protected currIdx: number

  constructor() {
    this.stack = []
    this.currIdx = -1
  }

  add(item: T) {
    if (item === this.stack[this.stack.length - 1]) return
    this.stack = [...this.stack.slice(0, this.currIdx + 1), item]
    this.currIdx = this.stack.length - 1
  }

  getAll() {
    return this.stack
  }

  getCurrent() {
    return this.stack[this.currIdx]
  }

  hasPrevious() {
    return this.currIdx > 0
  }

  hasNext() {
    return this.currIdx < this.stack.length - 1
  }

  getPrevious() {
    this.currIdx = this.currIdx >= 0 ? this.currIdx - 1 : -1
    return this.currIdx === -1 ? null : this.stack[this.currIdx]
  }

  getNext() {
    this.currIdx =
      this.currIdx + 1 < this.stack.length
        ? this.currIdx + 1
        : this.stack.length
    return this.currIdx === this.stack.length ? null : this.stack[this.currIdx]
  }
}

export function captureError(...args: any) {
  const ctx = args.slice(1)
  if (!isProductionEnv()) {
    // eslint-disable-next-line no-console
    console.error(...args)
  }

  withScope(scope => {
    if (ctx.length > 0) {
      scope.setContext('Context', ctx)
    }
    captureException(args[0])
  })
}

export function getEmbedCode(flow: Flow) {
  const embedUrl = `${getBaseUrl(true)}/${flow.id}?embed`

  let paddingBottom = '0px'
  if (flow.flowWrapper === FlowWrapper.none) {
    paddingBottom = `${flow.aspectRatio * 100}%`
  } else {
    paddingBottom = `calc(${flow.aspectRatio * 100}% + 41px)`
  }

  return `<div style="position: relative; padding-bottom: ${paddingBottom}; height: 0;"><iframe src="${embedUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`
}

import { useSessionStorageValue } from '@react-hookz/web'
import omit from 'lodash/omit'
import { useEffect } from 'react'
import { UserProfile, UserProfileData } from 'src/types'

import { getFirebaseEntity, useFirebaseEntity } from './firebase'
import { UserProfileDoc } from './types'

const COLLECTION_NAME = 'users'

function getUserProfileFromDoc(
  data: UserProfileDoc
): UserProfileData | undefined {
  if (!data) return undefined

  const isProUser =
    !!data.customerId && !!data.currentSubscriber && !data.isActiveMemberOfTeam
  const isFreeUser = !data.isActiveMemberOfTeam && !isProUser
  return {
    ...data,
    isFreeUser,
    isProUser,
  }
}

function getUpdateData(
  updates: Partial<UserProfileData>
): Partial<UserProfileDoc> {
  return omit(updates, ['isFreeUser', 'isProUser'])
}

export function useUserProfile(userId: string | null) {
  const { entity: userProfile, loading } = useFirebaseEntity<
    UserProfile,
    UserProfileData,
    UserProfileDoc
  >(
    COLLECTION_NAME,
    userId as string,
    getUserProfileFromDoc,
    getUpdateData,
    true,
    userId === null
  )
  return { userProfile, loading }
}

export function useCachedUserProfile(
  userId: string | null
): Omit<UserProfile, 'update' | 'delete'> | undefined {
  const { userProfile, loading } = useUserProfile(userId)
  const [cachedUserProfile, setCachedUserProfile] = useSessionStorageValue<
    Omit<UserProfile, 'update' | 'delete'> | undefined
  >('userProfile', userProfile)

  useEffect(() => {
    if (!loading && userProfile) {
      setCachedUserProfile(omit(userProfile, ['update', 'delete']))
    }
  }, [userProfile, loading, setCachedUserProfile])

  return cachedUserProfile ?? undefined
}

export async function getUserProfile(userId: string | null) {
  if (!userId) return
  const flow = getFirebaseEntity<UserProfile, UserProfileData, UserProfileDoc>(
    COLLECTION_NAME,
    userId,
    getUserProfileFromDoc,
    getUpdateData
  )

  return flow
}

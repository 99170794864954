import hoistNonReactStatics from 'hoist-non-react-statics'
import omit from 'lodash/omit'
import {
  AuthAction,
  PageURL,
  useAuthUser,
  withAuthUser,
} from 'next-firebase-auth'
import { ComponentType, useCallback, useMemo } from 'react'
import { defaultUser, UserProvider } from 'src/contexts/UserContext'
import { isBrowser } from 'src/helpers'
import { useCachedUserProfile } from 'src/store/userProfile'
import { User } from 'src/types'

type WithAuthUserOptions = {
  whenAuthed?: AuthAction.RENDER | AuthAction.REDIRECT_TO_APP
  whenAuthedBeforeRedirect?:
    | AuthAction.RENDER
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedBeforeInit?:
    | AuthAction.RENDER
    | AuthAction.REDIRECT_TO_LOGIN
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedAfterInit?: AuthAction.RENDER | AuthAction.REDIRECT_TO_LOGIN
  appPageURL?: PageURL
  authPageURL?: PageURL
  LoaderComponent?: ComponentType | null
}

/**
 * HOC that extends withAuthUser and loads the User in memory
 * For convenience, we default the next-firebase-auth options to:
 * - whenUnauthed(Before|After)Init: REDIRECT_TO_LOGIN
 */

function withUser<ComponentProps>(
  options?: WithAuthUserOptions
): (component: ComponentType<ComponentProps>) => ComponentType<ComponentProps> {
  return (ChildComponent: ComponentType<ComponentProps>) => {
    const WithUserHOC = (props: ComponentProps) => {
      const user = useInitUser()

      return (
        <UserProvider user={user}>
          <ChildComponent {...props} />
        </UserProvider>
      )
    }
    WithUserHOC.displayName = 'WithUserHOC'
    hoistNonReactStatics(WithUserHOC, ChildComponent)

    return withAuthUser<ComponentProps>({
      whenUnauthedBeforeInit: AuthAction.REDIRECT_TO_LOGIN,
      whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
      ...(options ? options : {}),
    })(WithUserHOC)
  }
}

export default withUser

function useInitUser(): User | undefined {
  const authUser = useAuthUser()
  const userProfile = useCachedUserProfile(authUser.id)

  const signOut = useCallback(async () => {
    await authUser.signOut()
    if (isBrowser && window.sessionStorage) {
      window.sessionStorage.clear()
    }
  }, [authUser])

  return useMemo(() => {
    if (!authUser.id || !authUser.email) {
      return
    }

    // see User type for more info about usage
    return {
      ...omit(authUser, 'serialize'),
      isLoggedIn: Boolean(authUser.id),
      email: userProfile?.email ?? authUser.email,
      name: userProfile?.name ?? authUser.displayName,
      photoURL: userProfile?.photoURL ?? authUser.photoURL,
      signOut,
      group: userProfile?.group ?? null,
      intercomUserId: userProfile?.intercomUserId ?? null,
      intercomUserHash: userProfile?.intercomUserHash ?? null,
      prefs: userProfile?.prefs ?? null,
      features: userProfile?.features ?? null,
      currentSubscriber:
        userProfile?.currentSubscriber ?? defaultUser.currentSubscriber,
      customerId: userProfile?.customerId ?? null,
      activeTeamId: userProfile?.activeTeamId ?? null,
      isActiveMemberOfTeam:
        userProfile?.isActiveMemberOfTeam ?? defaultUser.isActiveMemberOfTeam,
      isExternalMemberOfTeam:
        userProfile?.isExternalMemberOfTeam ??
        defaultUser.isExternalMemberOfTeam,
      isTeamAdmin: userProfile?.isTeamAdmin ?? defaultUser.isTeamAdmin,
      hasActiveInvitationToTeam:
        userProfile?.hasActiveInvitationToTeam ??
        defaultUser.hasActiveInvitationToTeam,
      isFreeUser: userProfile?.isFreeUser ?? defaultUser.isFreeUser,
      isProUser: userProfile?.isProUser ?? defaultUser.isProUser,
      onboardingUseCases: {
        Marketing:
          userProfile?.onboardingUseCases?.Marketing ??
          defaultUser.onboardingUseCases?.Marketing,
        Product:
          userProfile?.onboardingUseCases?.Product ??
          defaultUser.onboardingUseCases?.Product,
        Sales:
          userProfile?.onboardingUseCases?.Sales ??
          defaultUser.onboardingUseCases?.Sales,
        CustomerSuccess:
          userProfile?.onboardingUseCases?.CustomerSuccess ??
          defaultUser.onboardingUseCases?.CustomerSuccess,
        Other:
          userProfile?.onboardingUseCases?.Other ??
          defaultUser.onboardingUseCases?.Other,
      },
      onboardingCompanySize:
        userProfile?.onboardingCompanySize ?? defaultUser.onboardingCompanySize,
      getStartedArticlesHidden:
        userProfile?.getStartedArticlesHidden ??
        defaultUser.getStartedArticlesHidden,
    } as User
  }, [authUser, userProfile, signOut])
}
